import React, { useState } from "react";
import { Link as LinkR } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { Bio } from "../data/constants";
import { MenuRounded } from "@mui/icons-material";

const Nav = styled.div`
  background-color: ${({ theme }) => theme.bg};
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
`;

const NavbarContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
`;

const NavLogo = styled(LinkR)`
  width: 80%;
  padding: 0 6px;
  font-weight: 500;
  font-size: 19px;
  text-decoration: outline;
  color: inherit;
`;

const NavItems = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 0 6px;
  list-style: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled.a`
  color: ${({ theme }) => theme.text_primary};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  position: relative; // Ensure the line can be positioned below the link

  // Commented out the hover color change animation
  /* &:hover {
    color: ${({ theme }) => theme.primary};
  } */

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px; /* Line thickness */
    background-color: #fff; /* Set line color to white */
    transition: width 0.5s ease; /* Slower animation (0.5s instead of 0.3s) */
  }

  // Add animation when the link is clicked or focused
  &:hover::after {
    width: 100%; // Expand to full width on hover
  }

  // Optionally, maintain line when clicked (active state)
  &.active::after {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 6px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const GithubButton = styled.a`
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.text_primary};
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  border: 2px solid transparent;
  text-decoration: none;
  &:hover {
    background: transparent;
    border: 2px solid ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.text_primary};
  }
`;

const MobileIcon = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text_primary};
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const MobileMenu = styled.ul`
  width: 100%;
  display: flex;
  font-size: 17px;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  align-items: center;
  gap: 17px;
  padding: 0 6px;
  list-style: none;
  width: 100%;
  padding: 15px 30px 24px 30px;
  background: ${({ theme }) => theme.card_light};
  position: absolute;
  top: 80px;
  right: 0;

  transition: all 0.6s ease-in-out;
  transform: ${({ isOpen }) =>
    isOpen ? "translateY(0)" : "translateY(-100%)"};
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  z-index: ${({ isOpen }) => (isOpen ? "1000" : "-1000")};
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(""); // Track active link
  const theme = useTheme();

  const handleClick = (linkId) => {
    setActiveLink(linkId); // Set active link when clicked
  };

  return (
    <Nav>
      <NavbarContainer>
        <NavLogo
          to="https://beacons.ai/lakshaydhoundiyal"
          target="_blank"
          style={{
            textDecoration: "dotted",
            color: theme.text_primary,
          }}
        >
          Lakshay Dhoundiyal
        </NavLogo>

        <MobileIcon onClick={() => setIsOpen(!isOpen)}>
          <MenuRounded style={{ color: "inherit" }} />
        </MobileIcon>

        <NavItems>
          <NavLink
            href="#About"
            className={activeLink === "About" ? "active" : ""}
            onClick={() => handleClick("About")}
          >
            About
          </NavLink>
          <NavLink
            href="#Skills"
            className={activeLink === "Skills" ? "active" : ""}
            onClick={() => handleClick("Skills")}
          >
            Skills
          </NavLink>
          <NavLink
            href="#Experience"
            className={activeLink === "Experience" ? "active" : ""}
            onClick={() => handleClick("Experience")}
          >
            Experience
          </NavLink>
          <NavLink
            href="#Projects"
            className={activeLink === "Projects" ? "active" : ""}
            onClick={() => handleClick("Projects")}
          >
            Projects
          </NavLink>
          <NavLink
            href="#Education"
            className={activeLink === "Education" ? "active" : ""}
            onClick={() => handleClick("Education")}
          >
            Education
          </NavLink>
          <NavLink
            href="#Contact"
            className={activeLink === "Contact" ? "active" : ""}
            onClick={() => handleClick("Contact")}
          >
            Contact
          </NavLink>
        </NavItems>

        {isOpen && (
          <MobileMenu isOpen={isOpen}>
            <NavLink onClick={() => setIsOpen(!isOpen)} href="#About">
              About
            </NavLink>
            <NavLink onClick={() => setIsOpen(!isOpen)} href="#Skills">
              Skills
            </NavLink>
            <NavLink onClick={() => setIsOpen(!isOpen)} href="#Experience">
              Experience
            </NavLink>
            <NavLink onClick={() => setIsOpen(!isOpen)} href="#Projects">
              Projects
            </NavLink>
            <NavLink onClick={() => setIsOpen(!isOpen)} href="#Education">
              Education
            </NavLink>
            <NavLink onClick={() => setIsOpen(!isOpen)} href="#Contact">
              Contact
            </NavLink>
            <GithubButton
              href={Bio.hashnode}
              target="_Blank"
              style={{
                background: theme.primary,
                color: theme.text_primary,
              }}
            >
              My Blogs
            </GithubButton>
          </MobileMenu>
        )}

        <ButtonContainer>
          <GithubButton href={Bio.hashnode} target="_Blank">
            Read my Blogs
          </GithubButton>
        </ButtonContainer>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
