import React, { useState } from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import styled from "styled-components";

const Top = styled.div`
  width: 100%;
  display: flex;
  max-width: 100%;
  gap: 12px;
`;

const Image = styled.img`
  height: 50px;
  border-radius: 10px;
  margin-top: 4px;
  @media only screen and (max-width: 768px) {
    height: 40px;
  }
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const School = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const Degree = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.text_secondary};
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Date = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.text_secondary};

  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

const Description = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary};
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Grade = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text_secondary};
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Span = styled.div`
  display: -webkit-box;
  max-width: 100%;
`;

const EducationCard = ({ education }) => {
  // State to track if the card is clicked
  const [isActive, setIsActive] = useState(false);

  // Toggle the active state when the card is clicked
  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <VerticalTimelineElement
      icon={
        <img
          width="100%"
          height="100%"
          alt={education?.school}
          style={{ borderRadius: "50%", objectFit: "cover" }}
          src={education?.img}
        />
      }
      contentStyle={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        background: "#1d1836",
        color: "#fff",
        boxShadow: "rgba(23, 92, 230, 0.15) 0px 0px 40px 5px",
        backgroundColor: "rgba(17, 25, 40, 0.83)",
        border: isActive
          ? "10px solid red" // Change border to red when active
          : "3px solid rgba(255, 255, 255, 0.15)", // Default border
        borderRadius: "6px",
        borderImage: isActive
          ? "linear-gradient(to right, #4a00e0, #8e2de2) 1" // Keep gradient if active
          : "linear-gradient(to right, #8e2de2, #4a00e0) 1", // Default gradient
        filter: isActive ? "brightness(1.2)" : "brightness(1)", // Increase brightness when active
        transition: "all 0.3s ease", // Smooth transition for styles
        cursor: "pointer", // Set cursor to pointer when hovered or clicked
      }}
      contentArrowStyle={{
        borderRight: isActive
          ? "10px solid #fff" // Change arrow color to white when active
          : "10px solid rgba(255, 255, 255, 0.5)", // Default arrow color
      }}
      date={education?.date}
      onClick={handleClick} // Handle click to toggle active state
      // Hover effects for brightness and gradient inversion
      onMouseEnter={(e) => {
        e.currentTarget.style.filter = "brightness(2)"; // Increase brightness on hover
        e.currentTarget.style.borderImage = "linear-gradient(to right, #8e2de2, #4a00e0)"; // Invert gradient on hover
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.filter = "brightness(1)"; // Reset brightness
        e.currentTarget.style.borderImage = "linear-gradient(to right, #4a00e0, #8e2de2)"; // Reset gradient
      }}
    >
      <Top>
        <Image src={education?.img} />
        <Body>
          <School>{education?.school}</School>
          <Degree>{education?.degree}</Degree>
          <Date>{education?.date}</Date>
        </Body>
      </Top>
      <Grade>
        <b>Grade: </b>
        {education?.grade}
      </Grade>
      <Description>
        {education?.desc && <Span>{education.desc}</Span>}
      </Description>
    </VerticalTimelineElement>
  );
};

export default EducationCard;
